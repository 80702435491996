/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Layout } from 'antd';
import SideMenuView from "./component/sidebar/SideMenuView";
import HeaderView from "./component/header/HeaderView";
import './LayoutView.less'

const { Content } = Layout;

const LayoutView = ({ children }:any) => {   

    const [pageHeader, setPageHeader] = useState('');
    const [headerItemChange, setHeaderItemChange] = useState(true)
    const [headerItems, setHeaderItems] = useState(null)

    const child =  React.Children.map(children, (child) => {   
            return React.cloneElement(child, {
                // setPageHeader: setPageHeader
                setPageHeader,
                headerItems,
                setHeaderItemChange,
            });
    });

    return (
        <Layout className="main-layout">
            <SideMenuView />
            <Layout>
                <HeaderView />
                <Content className='content-layout'>
                        {child}
                </Content>
            </Layout>
        </Layout>   
    );
}


export default LayoutView